import React, { useEffect } from "react";

const TradingView = () => {
  useEffect(() => {
    // Ensure the script is available in the global scope
    const { TradingView } = window;

    if (TradingView) {
      new TradingView.widget({
        container: "chartContainer",
        locale: "en",
        library_path: "/charting_library/",
        datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
          "https://demo-feed-data.tradingview.com"
        ),
        symbol: "AAPL",
        interval: "1D",
        fullscreen: false,
        width: "100%",
        height: "100%",
        debug: true,
        theme: "Dark",
      });
    }
  });

  return (
    <div
      id="chartContainer"
      className="container"
      style={{ height: "100vh", width: "100%" }}
    ></div>
  );
};

export default TradingView;
