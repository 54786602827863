import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";

import {
  loginSuccess,
  angelId,
  deleteBroker,
  addItem,
  removeItem,
  brokerLogin,
  userSchemaRedux,
  allClientData,
} from "../actions/actions";
import { UseSelector } from "react-redux";
import "./broker.css";
import "./loader.css";
import { toast, ToastContainer } from "react-toastify";

import { Button, Dropdown, Form, Nav, NavDropdown } from "react-bootstrap";
import axios from "axios";
import delete_broker from "../images/delete_broker.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { logout } from "../actions/logout";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import { Triangle } from "react-loader-spinner";
import { ProductionUrl } from "../URL/url";
import Skeleton from "@mui/material/Skeleton";

function Listofbroker({ setLoading }) {
  const userSchema = useSelector((state) => state.account.userSchemaRedux);
  const clientdata = useSelector((state) => state.account.allClientData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state) => state.email.email);
  const items = useSelector((state) => state.account.items);
  const [index, setIndex] = useState();
  const [clientId, setClientId] = useState();
  // const items = items1[0]
  const Email = useSelector((state) => state.email.email);
  let userExist = false;
  // let existingAlias = false;
  const [existingAlias, setExistingAlias] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessage2, setAlertMessage2] = useState("");
  const [id, insertid] = useState("");
  const [isConfirmToggleOpen, setIsConfirmToggleOpen] = useState(false); // For toggle confirmation modal

  const cancelToggle = () => {
    setIsConfirmToggleOpen(false); // Close the modal without toggling
  };

  const confirmToggle = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${url}/removeClient`, {
        Email,
        index,
        clientId,
      });
      setLoading(false);
      const profileData = await axios.post(`${url}/userinfo`, { Email });
      dispatch(allClientData(profileData.data));
      const dbschema = await axios.post(`${url}/dbSchema`, { Email });
      dispatch(userSchemaRedux(dbschema.data));
      console.log(response.data);
      // Swal.fire({
      //   position: "center",
      //   icon: "success",
      //   title: "Account removed successfully",
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
      setisLoggedIn(false);
      dispatch(brokerLogin(false));
      dispatch(logout());
      dispatch(removeItem());
      // showAlertWithTimeout2("Broker removed successfully", 3000);
      toast.success("Account removed successfully", {
        position: "top-center",
        autoClose: 2000,
      });
      setIsConfirmToggleOpen(false);
    } catch (error) {
      console.error("Error:", error);
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: "Failed to remove broker",
      //   footer: '<a href="#">Why do I have this issue?</a>',
      // });
      toast.error("Failed to remove account", {
        position: "top-center",
        autoClose: 2000,
      });
      setIsConfirmToggleOpen(false);
    }
  };

  const [accountName, setAccountName] = useState("");
  const [pass, insertpass] = useState("");
  const checklogin = true;
  const [accountAliases, setAccountAliases] = useState({});
  // const [loading, setLoading] = useState(true);
  const [broker, addbroker] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [secretKey, insertSecretKey] = useState("");
  const [deltaSecret, insertDeltaSecret] = useState("");
  const [deltaKey, insertDeltaKey] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [addedId, insertaddedId] = useState("");
  const [apikey, insertApiKey] = useState("");
  const [selectBroker, setSelectBroker] = useState("1"); // Default to "AngelOne"
  const [angelIdExist, setAngelIdExist] = useState(false);
  const [deltaApiKeyExist, setDeltaApiKeyExist] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);

  const url =
    process.env.NODE_ENV === "production"
      ? ProductionUrl
      : "http://localhost:5000";

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const profileData = await axios.post(`${url}/userinfo`, { Email });
  //     console.log(profileData.data);
  //     dispatch(allClientData(profileData.data));
  //     console.log(userSchema);
  //     console.log(clientdata);
  //   };
  //   fetchData();
  // }, [email]);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoader(true);
      const profileData = await axios.post(`${url}/userinfo`, { Email });
      console.log(profileData);
      console.log(clientdata);
      dispatch(allClientData(profileData.data));
      const dbschema = await axios.post(`${url}/dbSchema`, { Email });
      dispatch(userSchemaRedux(dbschema.data));
      setAccountAliases(dbschema?.data?.AccountAliases);
      console.log(accountAliases);
      setTableLoader(false);
    };
    fetchData();
  }, []);

  const showAlertWithTimeout = (message, duration) => {
    setShowAlert(true);
    setAlertMessage(message);

    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, duration);
  };

  const showAlertWithTimeout2 = (message, duration) => {
    setShowAlert2(true);
    setAlertMessage2(message);

    setTimeout(() => {
      setShowAlert2(false);
      setAlertMessage2("");
    }, duration);
  };

  const checkAccountAlias = (accountName) => {
    const aliases = userSchema.AccountAliases || null;

    if (aliases) {
      // Check if accountName exists in AccountAliases
      const aliasExists = Object.values(aliases).includes(accountName);
      console.log("checking", aliasExists);

      if (aliasExists) {
        setExistingAlias(true);
        // setError("This alias already exists. Please choose a different one.");
      } else {
        setExistingAlias(false);

        // setError("");
      }
    }
  };

  // Handle input change for the account name
  const handleAccountNameChange = (e) => {
    const value = e.target.value;
    setAccountName(value);

    // Clear the previous timeout if the user is still typing
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to check alias after 500ms of inactivity
    const newTimeout = setTimeout(() => {
      checkAccountAlias(value); // Check alias after user stops typing
    }, 50);

    setTypingTimeout(newTimeout); // Store the new timeout
  };

  const addBrokerBtn = async (e) => {
    setLoading(true);
    document.body.style.overflow = "hidden";

    if (userSchema.AngelBrokerData != null) {
      userSchema.AngelBrokerData.map((item, index) => {
        if (item.AngelId == id) {
          userExist = true;
        }
      });
    }
    if (userSchema.AngelBrokerData != null) {
      userSchema.DeltaBrokerSchema.map((item, index) => {
        if (item.deltaApiKey == deltaKey) {
          userExist = true;
        }
      });
    }
    if (userSchema.AccountAliases != null) {
      setExistingAlias(
        Object.values(userSchema.AccountAliases).find(
          (alias) => alias.toLowerCase() === accountName.toLowerCase()
        )
      );
    }
    if (existingAlias) {
      showAlertWithTimeout("Account Name is not avaliable", 2000);
      setLoading(false);
    } else if (userExist) {
      // Swal.fire({
      //   position: "center",
      //   icon: "error",
      //   title: "Broker already added",
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
      showAlertWithTimeout("Broker already added", 2000);
      setLoading(false);
    } else {
      e.preventDefault();
      console.log("broker add");
      try {
        if (selectBroker == 1) {
          const response = await axios.post(`${url}/addbroker`, {
            First: true,
            id: id,
            pass: pass,
            email: email,
            secretKey: secretKey,
            userSchema: userSchema,
            ApiKey: apikey,
            accountName: accountName,
          });

          console.log("when user enter angelid and pass" + response.data);
          if (!response.data) {
            showAlertWithTimeout("Invalid id or password", 5000);
            insertid("");
            insertpass("");
            toast.error("Invalid credential", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            dispatch(loginSuccess(response));
            dispatch(angelId({ id, pass }));
            addbroker();

            // dispatch(addItem(id))
            console.log(response.data.userSchema);

            // showAlertWithTimeout2("Successfully added", 3000);
            // Swal.fire({
            //   position: "center",
            //   icon: "success",
            //   title: "Account added successfully",
            //   showConfirmButton: false,
            //   timer: 1500,
            // });
            toast.success("Account added successfully", {
              position: "top-center",
              autoClose: 2000,
            });
            const dbschema = await axios.post(`${url}/dbSchema`, { Email });

            dispatch(userSchemaRedux(dbschema.data));
            if (dbschema.data.BrokerCount) {
              dispatch(brokerLogin(true));
            } else {
              dispatch(brokerLogin(false));
            }

            insertaddedId(id);
            insertid("");
            insertpass("");
            insertSecretKey("");
            setisLoggedIn(true);

            console.log("user data is " + response.data.data.net);
            const profileData = await axios.post(`${url}/userinfo`, { Email });
            console.log(profileData.data);
            dispatch(allClientData(profileData.data));
          }
        } else {
          const response = await axios.post(`${url}/addDeltaBroker`, {
            email: email,
            apiKey: deltaKey,
            apiSecret: deltaSecret,
          });
          const dbschema = await axios.post(`${url}/dbSchema`, { Email });

          dispatch(userSchemaRedux(dbschema.data));
          if (dbschema.data.BrokerCount) {
            dispatch(brokerLogin(true));
          } else {
            dispatch(brokerLogin(false));
          }

          if (response.data.success) {
            // showAlertWithTimeout2("Successfully added", 3000);
            // Swal.fire({
            //   position: "center",
            //   icon: "success",
            //   title: "Account added successfully",
            //   showConfirmButton: false,
            //   timer: 1500,
            // });
            toast.success("Successfully deployed", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            showAlertWithTimeout("Invalid id or password", 5000);
            toast.error("Invalid credentials", {
              position: "top-center",
              autoClose: 2000,
            });
            insertid("");
            insertpass("");
          }
          const profileData = await axios.post(`${url}/userinfo`, { Email });
          console.log(profileData.data);
          dispatch(allClientData(profileData.data));
          insertDeltaSecret("");
          insertDeltaKey("");
          setAccountName("");
        }
      } catch (e) {
        console.log("Error is " + e);
      }
      setLoading(false);
      document.body.style.overflow = "unset";
      console.log(broker);
    }
  };

  const delete_broker_fun = async (index, clientId) => {
    console.log(index, clientId);
    setIsConfirmToggleOpen(true);
    setIndex(index);
    setClientId(clientId);
  };

  const handleAngelId = (e) => {
    const value = e.target.value;
    insertid(value);
    // Clear the previous timeout to reset the debounce
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to check the alias after 500ms of inactivity
    const newTimeout = setTimeout(() => {
      // Check if the value exists in the BrokerIds
      const exists = userSchema.BrokerIds.includes(value);

      // Log the result
      if (exists) {
        console.log("exist", value);
        setAngelIdExist(true); // If exists, set angelIdExist to false
      } else {
        console.log("Not exist", value);
        setAngelIdExist(false); // If not exists, set angelIdExist to true
      }
    }, 500); // Increased timeout for better user input handling

    setTypingTimeout(newTimeout); // Save the new timeout to state
  };

  if (localStorage.getItem("theme") == "dark-theme") {
    document.body.className = "dark-theme";
  }

  const help = () => {
    navigate("/home");
  };

  const handleDeltaKeyChange = (e) => {
    const value = e.target.value;
    insertDeltaKey(value);
    // Clear the previous timeout to reset the debounce
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to check the alias after 500ms of inactivity
    const newTimeout = setTimeout(() => {
      // Check if the value exists in the BrokerIds
      const deltaApiKeyExists = userSchema.DeltaBrokerSchema.some(
        (item) => item.deltaApiKey === value
      );

      if (deltaApiKeyExists) {
        console.log("Delta API Key exists in the database");
        setDeltaApiKeyExist(true); // Update this logic based on your needs
      } else {
        console.log("Delta API Key does not exist in the database");
        setDeltaApiKeyExist(false); // Update this logic based on your needs
      }
    }, 500); // Increased timeout for better user input handling

    setTypingTimeout(newTimeout);
  };
  return (
    <div className={`container`}>
      <ToastContainer />

      {/* <div
        className={`${showAlert ? "alert alert-danger show " : ""}`}
        style={{ zIndex: 5 }}
      >
        {alertMessage}
      </div>
      <div className={`${showAlert2 ? "alert alert-success show mt-4" : ""}`}>
        {alertMessage2}
      </div> */}

      {/* {loading && (
        <div className="loader2 ">
          <div className="loader ">
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__ball"></div>
          </div>
        </div>
      )} */}

      <div
        className={`broker-list  p-2 ${
          localStorage.getItem("theme") === "light-theme"
            ? ""
            : "dark-theme-class"
        }`}
      >
        <div className="row">
          <span
            className="list-title mt-3 ms-3"
            style={{ fontSize: "1.8rem", textAlign: "left", fontWeight: "600" }}
          >
            Add Broker
          </span>
          <div className="col-12 p-3 d-flex flex-wrap align-items-start">
            {/* Input fields and button row */}
            <div
              className="broker-guide"
              style={{
                display: "flex",
                gap: "1em",
                alignItems: "center",
              }}
            >
              <div className="d-flex flex-column flex-md-row justify-content-between w-100 mt-3 gap-3 qsxpog">
                <select
                  className="form-select mb-md-0 input-focus-yellow"
                  aria-label="Broker selection"
                  style={{
                    width: "100%",
                    maxWidth: "227px",
                    boxShadow: "none",
                    color: "gray",
                  }}
                  onChange={(e) => {
                    insertid("");
                    setAccountName("");
                    insertDeltaKey("");
                    setExistingAlias(false);
                    setAngelIdExist(false);
                    setDeltaApiKeyExist(false);
                    setSelectBroker(e.target.value);
                  }}
                  value={selectBroker}
                >
                  <option value="1">AngelOne</option>
                  <option value="2">Delta</option>
                  <option value="3">Upstox</option>
                </select>
              </div>
              <a
                href="https://xalgos.in/SmartApiGuide.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#FCD535",
                  textDecoration: "none",
                  fontSize: "20px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                title="Click here for the guide"
                className="mt-3"
              >
                ?
              </a>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between w-100 mt-3 gap-3 qsxpog">
              <div className="w-100">
                <input
                  type="text"
                  className="form-control account-name mb-2 mb-md-0 input-focus-yellow"
                  placeholder="Account Name"
                  value={accountName}
                  onChange={handleAccountNameChange}
                  required
                  style={{ width: "100%" }}
                />
                {existingAlias && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      textAlign: "start",
                      marginBottom: "0",
                    }}
                    className="broker-exist-p"
                  >
                    Account name already exist
                  </p>
                )}
              </div>
              {selectBroker == 1 && (
                <>
                  <div className="w-100">
                    <input
                      type="text"
                      className="form-control angel-client-id mb-2 mb-md-0 input-focus-yellow"
                      placeholder="Client ID"
                      value={id}
                      onChange={(e) => {
                        handleAngelId(e);
                        insertid(e.target.value);
                      }}
                      required
                      style={{ width: "100%" }}
                    />
                    {angelIdExist && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          textAlign: "start",
                          marginBottom: "2px",
                        }}
                        className="broker-exist-p"
                      >
                        Broker already exist
                      </p>
                    )}
                  </div>

                  <input
                    type="text"
                    className="form-control mb-2 mb-md-0 input-focus-yellow"
                    placeholder="PIN"
                    value={pass}
                    onChange={(e) => insertpass(e.target.value)}
                    required
                    style={{ width: "100%" }}
                  />

                  <input
                    type="text"
                    className="form-control mb-2 mb-md-0 input-focus-yellow"
                    placeholder="Totp Key"
                    value={secretKey}
                    onChange={(e) => insertSecretKey(e.target.value)}
                    required
                    style={{ width: "100%" }}
                  />
                  <input
                    type="text"
                    className="form-control mb-2 mb-md-0 input-focus-yellow"
                    placeholder="Api Key"
                    value={apikey}
                    onChange={(e) => insertApiKey(e.target.value)}
                    required
                    style={{ width: "100%" }}
                  />
                </>
              )}
              {selectBroker == 2 && (
                <>
                  <div className="w-100">
                    <input
                      type="text"
                      className="form-control mb-2 mb-md-0 input-focus-yellow"
                      placeholder="API Key"
                      value={deltaKey}
                      onChange={(e) => {
                        handleDeltaKeyChange(e);
                        insertDeltaKey(e.target.value);
                      }}
                      required
                      style={{ width: "100%" }}
                    />
                    {deltaApiKeyExist && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          textAlign: "start",
                          marginBottom: "0",
                        }}
                        className="broker-exist-p"
                      >
                        Apikey already exist
                      </p>
                    )}
                  </div>
                  <input
                    type="text"
                    className="form-control mb-2 mb-md-0 input-focus-yellow"
                    placeholder="API Secret"
                    value={deltaSecret}
                    onChange={(e) => insertDeltaSecret(e.target.value)}
                    required
                    style={{ width: "100%" }}
                  />
                </>
              )}
            </div>
            <button
              className="btn mt-3 w-100"
              color="var(--text-color)"
              style={{ backgroundColor: "#FBD535", border: "none" }}
              onClick={addBrokerBtn}
              disabled={existingAlias || angelIdExist}
            >
              Add Broker
            </button>
          </div>

          {tableLoader ? (
            <div className="skeleton-card ">
              <Skeleton height={60} width="100%" className="" />
              <Skeleton height={60} width="100%" className="" />
              <Skeleton height={60} width="100%" className="" />
              <Skeleton height={60} width="100%" className="" />
            </div>
          ) : (
            <div className="container evlyd mt-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th style={{ width: "15%" }} className="edcvl">
                        Client ID
                      </th>
                      <th style={{ width: "15%" }} className="edcvl">
                        Account alias
                      </th>
                      <th
                        style={{ width: "30%" }}
                        className={
                          localStorage.getItem("theme") === "light-theme"
                            ? ""
                            : "dark-theme-class"
                        }
                      >
                        Name
                      </th>
                      <th
                        style={{ width: "10%", textAlign: "center" }}
                        className={
                          localStorage.getItem("theme") === "light-theme"
                            ? ""
                            : "dark-theme-class"
                        }
                      >
                        Date
                      </th>
                      <th
                        style={{ width: "15%", textAlign: "center" }}
                        className={
                          localStorage.getItem("theme") === "light-theme"
                            ? ""
                            : "dark-theme-class"
                        }
                      >
                        Broker Name
                      </th>
                      <th
                        style={{ width: "10%", textAlign: "center" }}
                        className={
                          localStorage.getItem("theme") === "light-theme"
                            ? ""
                            : "dark-theme-class"
                        }
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {clientdata.map((item, index) => (
                      <tr key={index}>
                        {/* Displaying client code */}
                        <td>
                          {item.userData
                            ? item.userData?.data?.clientcode
                            : item.balances?.result[0]?.user_id}
                        </td>

                        {/* Displaying account alias */}
                        <td>
                          {item.userData
                            ? accountAliases[item.userData?.data?.clientcode] // Fetch alias using client code
                            : accountAliases[
                                item.balances?.result[0]?.user_id
                              ] || "N/A"}
                        </td>

                        {/* Displaying user name */}
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.userData
                            ? item.userData?.data?.name?.toUpperCase()
                            : item.userDetails?.result?.first_name?.toUpperCase() +
                                " " +
                                item.userDetails?.result?.last_name.toUpperCase() ||
                              "N/A"}
                        </td>

                        {/* Hardcoded or dynamic date (adjust as needed) */}
                        <td>
                          {item.userData
                            ? userSchema.AngelBrokerData.find(
                                (broker) =>
                                  broker.AngelId ===
                                  item.userData?.data?.clientcode
                              ).Date
                            : userSchema.DeltaBrokerSchema?.find(
                                (broker) =>
                                  broker.deltaBrokerId ===
                                  item.balances?.result[0]?.user_id
                              )?.Date || "N/A"}
                        </td>

                        {/* Displaying Broker Name with Skeleton if loading */}
                        <td>
                          {item.userData ? (
                            "AngelOne"
                          ) : item.deltaApiKey ? (
                            "Delta"
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </td>

                        {/* Delete action */}
                        <td>
                          <img
                            src={delete_broker}
                            height={20}
                            className="delete-icon"
                            alt="Delete Broker"
                            onClick={() =>
                              delete_broker_fun(
                                index,
                                item.userData
                                  ? item.userData?.data?.clientcode
                                  : item.balances?.result[0]?.user_id
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isConfirmToggleOpen}
        onRequestClose={cancelToggle}
        contentLabel="Confirm Toggle"
        className="confirm-modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <h3>Are you sure?</h3>
          <p>Do you want to delete?</p>
          <div className="modal-actions">
            <button className="cancel-btn" onClick={cancelToggle}>
              Cancel
            </button>
            <button
              className="confirm-btn"
              onClick={confirmToggle}
              style={{
                backgroundColor: "red",
                color: "white", // Ensures text is visible on colored background
                borderRadius: "0.2em",
                fontSize: "1.1em",
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Listofbroker;
export const id = () => id;
export const pass = () => pass;
