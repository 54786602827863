import React, { useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import "./LiveTrade.css"; // Create this CSS file for custom styling
import _ from "lodash"; // Install lodash for throttling

function LiveTrade({ darkMode, toggleDarkMode, setLoading }) {
  const [data, setData] = useState({});
  const socket1 = useRef(null); // Use ref to persist WebSocket instance
  const throttledSetData = useRef(
    _.throttle((newData) => setData(newData), 500) // Update state every 500ms
  );

  useEffect(() => {
    socket1.current = new WebSocket("ws:/localhost:5000/ws1");

    socket1.current.addEventListener("message", (event) => {
      const receivedData = JSON.parse(event.data);
      throttledSetData.current(receivedData);
    });

    return () => {
      // Cleanup WebSocket connection on component unmount
      socket1.current.close();
    };
  }, []);

  return (
    <div>
      <Navbar
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        setLoading={setLoading}
      />
      <div className="LiveTrade">
        <div className="table-container container">
          <table className="livetrade-table">
            <thead>
              <tr>
                <th>Strategy Name</th>
                <th>Quantity</th>
                <th>Type</th>
                <th>Entry Time</th>
                <th>Entry Price</th>
                <th>P&L</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="stock-name">{data.strategyName}</div>
                </td>
                <td>{data.quantity}</td>
                <td>{data.side}</td>
                <td>{data.entryTime}</td>
                <td>{data.entryPrice}</td>
                <td
                  style={{
                    color: data.runningPnL >= 0 ? "green" : "red",
                  }}
                >
                  {data.runningPnL?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LiveTrade;
